






































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { mapState, mapActions, mapGetters } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import Form from "@/components/UI/form/Form.vue";
import FormBox from "@/components/UI/form/FormBox.vue";
import Input from "@/components/UI/form/Input.vue";
import Select from "@/components/UI/form/Select.vue";
import TextArea from "@/components/UI/form/TextArea.vue";
import InputRadio from "@/components/UI/form/InputRadio.vue";
import Button from "@/components/UI/Button.vue";

@Component({
  computed: {
    ...mapState("profile", [
      "profile",
      "changePasswordForm",
      "isSamePassword",
      "isChangePasswordFormValid",
      "error"
    ])
  },
  methods: {
    ...mapActions("profile", [
      "getProfile",
      "clearProfile",
      "onChangeHandler",
      "passwordChangeSubmit",
      "clearChangePasswordForm"
    ])
  },
  components: {
    Form,
    FormBox,
    Input,
    TextArea,
    InputRadio,
    Select,
    Button,
    PageLayout,
    Header,
    Footer
  }
})
export default class Profile extends Vue {
  public getProfile!: () => void;
  public clearProfile!: () => void;

  public mounted(): void {
    this.getProfile();
  }
  public destroyed() {
    this.clearProfile();
  }
}
